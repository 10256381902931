import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { supabase } from '../../supabase';  

interface Deadline {
  organization_id: string;
  title: string;
  description?: string;
  due_date: string;
}

interface Organization {
  organization_id: string;
  name: string;
}

const CreateDeadline: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [deadlines, setDeadlines] = useState<Deadline[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [newDeadline, setNewDeadline] = useState<Deadline>({
    organization_id: '',
    title: '',
    due_date: '',
  });

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    const positionId = localStorage.getItem('positionId');
    if (!positionId) {
      console.error('Position ID not found in local storage');
      return;
    }

    const { data, error } = await supabase
      .from('organizations')
      .select('organization_id, name')
      .eq('entity_handler', positionId);

    if (error) {
      console.error('Error fetching organizations:', error);
    } else {
      setOrganizations(data || []);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewDeadline(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setDeadlines(prev => [...prev, newDeadline]);
    setNewDeadline({ organization_id: '', title: '', due_date: '' });
    setShowForm(false);
  };

  const handleSendDeadlines = async () => {
    const positionId = localStorage.getItem('positionId');
    if (!positionId) {
      console.error('Position ID not found in local storage');
      return;
    }

    try {
      const { data, error } = await supabase.rpc('bulk_insert_deadlines', {
        deadlines_json: deadlines.map(d => ({
          ...d,
          creator_position_id: parseInt(positionId),
        })),
      });

      if (error) {
        console.error('Error sending deadlines:', error);
        // Here you might want to show an error message to the user
        alert(`Error sending deadlines: ${error.message}`);
      } else {
        console.log('Deadlines sent successfully:', data);
        setDeadlines([]);
        // Here you might want to show a success message to the user
        alert('Deadlines sent successfully!');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Create Deadlines</h1>
        {deadlines.length > 0 && (
          <button 
            onClick={handleSendDeadlines}
            className="bg-purple-700 text-white px-4 py-2 rounded-md hover:bg-purple-800 transition duration-300"
          >
            Send Deadlines
          </button>
        )}
      </div>

      {!showForm && (
        <button
          onClick={() => setShowForm(true)}
          className="w-full bg-gray-100 hover:bg-gray-200 transition duration-300 py-4 rounded-md flex justify-center items-center"
        >
          <FaPlus className="text-gray-600" />
        </button>
      )}

      {showForm && (
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6 mb-6">
          <div className="mb-4">
            <label htmlFor="organization_id" className="block text-gray-700 text-sm font-bold mb-2">
              Organization
            </label>
            <select
              id="organization_id"
              name="organization_id"
              value={newDeadline.organization_id}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Select an organization</option>
              {organizations.map(org => (
                <option key={org.organization_id} value={org.organization_id}>{org.name}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="title" className="block text-gray-700 text-sm font-bold mb-2">
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={newDeadline.title}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={newDeadline.description}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="due_date" className="block text-gray-700 text-sm font-bold mb-2">
              Due Date
            </label>
            <input
              type="date"
              id="due_date"
              name="due_date"
              value={newDeadline.due_date}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Add Deadline
            </button>
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      {deadlines.length > 0 && (
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Created Deadlines</h2>
          <div className="space-y-4">
            {deadlines.map((deadline, index) => (
              <div key={index} className="bg-white shadow-md rounded-lg p-4">
                <h3 className="font-bold text-lg">{organizations.find(org => org.organization_id === deadline.organization_id)?.name}</h3>
                <p className="text-gray-600">{deadline.title}</p>
                <p className="text-gray-500 text-sm">Due: {deadline.due_date}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateDeadline;