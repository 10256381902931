import React, { useState, useEffect } from 'react';
import { FaReceipt } from 'react-icons/fa';
import { supabase } from '../../supabase';  

interface Deadline {
  id: string;
  organization_name: string;
  title: string;
  approved_at: string | null;
  approved_by: string | null;
  optional_link?: string;
}

const FinishedDeadline: React.FC = () => {
  const [deadlines, setDeadlines] = useState<Deadline[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchFinishedDeadlines();
  }, []);

  const fetchFinishedDeadlines = async () => {
    const positionId = localStorage.getItem('positionId');
    if (!positionId) {
      console.error('Position ID not found in local storage');
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase.rpc('get_finished_deadlines', {
        position_id: parseInt(positionId)
      });

      if (error) throw error;

      setDeadlines(data);
    } catch (error) {
      console.error('Error fetching finished deadlines:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Finished Deadlines For Organizations</h1>
      <div className="space-y-6">
        {deadlines.map((deadline) => (
          <div key={deadline.id} className="bg-white shadow-md rounded-lg p-6 flex justify-between items-center">
            <div className="flex-grow">
              <h2 className="text-xl font-bold text-gray-800">{deadline.organization_name}</h2>
              <p className="text-gray-600 mt-2">{deadline.title}</p>
            </div>
            <div className="flex items-center">
              {deadline.optional_link && (
                <button 
                  onClick={() => window.open(deadline.optional_link, '_blank')}
                  className="mr-4 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300"
                >
                  <FaReceipt />
                </button>
              )}
              <div className="w-px h-16 bg-gray-300 mr-4"></div>
              <div className="text-left">
                <div className="text-sm text-gray-500">
                  Approved on: {deadline.approved_at ? new Date(deadline.approved_at).toLocaleDateString() : 'N/A'}
                </div>
                <div className="text-sm text-gray-500 mt-1">
                  Approved by: {deadline.approved_by || 'N/A'}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FinishedDeadline;