import React, { useState, useEffect, useRef, MouseEvent as ReactMouseEvent, ChangeEvent } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoHomeOutline, IoSettingsOutline, IoHelpCircleOutline, IoCloseOutline } from 'react-icons/io5';
import { ReactComponent as Home } from '../../Assets/Icons/home.svg';
import { ReactComponent as Settings } from '../../Assets/Icons/settings.svg';
import { ReactComponent as Community } from '../../Assets/Icons/community.svg';
import Logo from '../../Assets/AgoraLogo.svg';
import { useAuth } from '../../Contexts/AuthContext';
import { FiCalendar } from 'react-icons/fi';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  icon: React.ReactElement;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children, icon }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`flex items-center px-4 py-2 rounded-md my-2 text-[#5F6776] hover:bg-[#F2F2F7] ${
        isActive ? 'bg-[#F2F2F7]' : ''
      }`}
    >
      <div className="text-blue-600">{React.cloneElement(icon, { className: 'mr-2', size: 28 })}</div>
      <span className="text-lg">{children}</span>
    </Link>
  );
};

interface Group {
  organization_id: string;
  name: string;
  role: string;
}

interface SidebarProps {
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onClose }) => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const [message, setMessage] = useState('');
  const popupRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <div className="flex flex-col w-64 h-screen px-4 py-8 bg-white border-r border-gray-200" style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #F7F7FB)' }}>
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <img src={Logo} alt="Agora Logo" className="w-12 h-auto" />
          <span className="ml-2 text-2xl font-bold">Agora</span>
        </div>
        <button onClick={onClose} className="lg:hidden">
          <IoCloseOutline size={24} />
        </button>
      </div>
      <div className="flex-grow">
        <NavLink to="/dashboard" icon={<Home />}>Dashboard</NavLink>
        <NavLink to="/tools" icon={<Community />}>Tools</NavLink>
        <NavLink to="/settings" icon={<Settings />}>Settings</NavLink>
        <div className="h-px my-4 bg-gray-200"></div>
      </div>
    </div>
  );
};

export default Sidebar;
