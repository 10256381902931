import React, { useState } from 'react';
import Sidebar from './Misc/Sidebar';
import AppHeader from './Dashboard/Components/AppHeader';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="flex h-screen bg-white">
      <div className={`lg:block ${isSidebarOpen ? 'block' : 'hidden'} fixed inset-y-0 left-0 z-50 w-64 transition-transform duration-300 ease-in-out transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:relative lg:translate-x-0`}>
        <Sidebar onClose={closeSidebar} />
      </div>

      {isSidebarOpen && (
        <div 
          className="fixed inset-0 z-40 bg-black bg-opacity-50 lg:hidden"
          onClick={closeSidebar}
        ></div>
      )}

      <div className="flex flex-col flex-grow">
        <AppHeader toggleSidebar={toggleSidebar} />
        <div className="overflow-auto bg-gray-50 h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;