import React from 'react';
import { Outlet, NavLink, useLocation } from 'react-router-dom';

interface SettingsTabProps {
  to: string;
  children: React.ReactNode;
}

const SettingsTab: React.FC<SettingsTabProps> = ({ to, children }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <NavLink
      to={to}
      className={`px-4 py-2 rounded-md font-medium transition-all duration-200 ${
        isActive
          ? 'bg-purple-600 text-white shadow-md'
          : 'text-gray-600 hover:bg-purple-100'
      }`}
    >
      {children}
    </NavLink>
  );
};

const Settings: React.FC = () => {
  return (
    <div className="min-h-screen bg-background">
      <div className="max-w-6xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">Settings</h1>
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="flex border-b border-gray-200">
            <SettingsTab to="/settings">Information</SettingsTab>
          </div>
          <div className="p-6 ">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;