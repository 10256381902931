import React, { useState, useEffect, useRef, useMemo } from 'react';
import { NavLink, Outlet, useLocation, Navigate } from 'react-router-dom';

const ToolManagement: React.FC = () => {
  const location = useLocation();
  // const subTabsRef = useRef<(HTMLAnchorElement | null)[]>([]);

  const tabs = useMemo(() => [
    { name: 'Pending', path: '' },
    { name: 'Finished', path: 'finished' },
    { name: 'Create', path: 'create' },
  ], []);

  // const getSubTabs = (tab: string) => {
  //   switch (tab) {
  //     case '':
  //       return [
  //         { name: 'All Pending', path: '' },
  //         { name: 'High Priority', path: 'high-priority' },
  //       ];
  //     case 'finished':
  //       return [
  //         { name: 'Recently Finished', path: '' },
  //         { name: 'Archive', path: 'archive' },
  //       ];
  //     case 'create':
  //       return [
  //         { name: 'New Tool', path: '' },
  //         { name: 'Import Tools', path: 'import' },
  //       ];
  //     default:
  //       return [];
  //   }
  // };

  const currentTab = useMemo(() => 
    tabs.find(tab => location.pathname.endsWith(tab.path)),
    [location.pathname, tabs]
  );

  // const subTabs = useMemo(() => 
  //   currentTab ? getSubTabs(currentTab.path) : [],
  //   [currentTab]
  // );

  // const [activeSubTab, setActiveSubTab] = useState('');
  // const [highlightStyle, setHighlightStyle] = useState({ left: 0, width: 0 });

  // useEffect(() => {
  //   const currentSubPath = location.pathname.split('/').pop();
  //   const matchingSubTab = subTabs.find(subTab => subTab.path === currentSubPath);
  //   const newActiveSubTab = matchingSubTab ? matchingSubTab.path : subTabs[0]?.path || '';
    
  //   if (newActiveSubTab !== activeSubTab) {
  //     setActiveSubTab(newActiveSubTab);
  //   }
  // }, [location.pathname, subTabs, activeSubTab]);

  // useEffect(() => {
  //   const updateHighlight = () => {
  //     const activeIndex = subTabs.findIndex(st => st.path === activeSubTab);
  //     if (activeIndex !== -1 && subTabsRef.current[activeIndex]) {
  //       const activeElement = subTabsRef.current[activeIndex];
  //       if (activeElement) {
  //         setHighlightStyle({
  //           left: activeElement.offsetLeft,
  //           width: activeElement.offsetWidth,
  //         });
  //       }
  //     }
  //   };

  //   updateHighlight();
  //   window.addEventListener('resize', updateHighlight);
  //   return () => window.removeEventListener('resize', updateHighlight);
  // }, [activeSubTab, subTabs]);

  if (location.pathname === '/tools') {
    return <Navigate to="/tools/" replace />;
  }

  return (
    <div className="p-4">
      <div className="flex mb-4 space-x-4 bg-white rounded-lg shadow-sm border border-agoraOutline">
        {tabs.map((tab) => (
          <NavLink
            key={tab.path}
            to={tab.path}
            className={({ isActive }) =>
              `px-4 py-2 ${
                isActive ? 'text-primaryButtonBg border-b-2 border-primaryButtonBg' : 'text-componentText'
              }`
            }
          >
            {tab.name}
          </NavLink>
        ))}
      </div>
      {/* {subTabs.length > 0 && (
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-2xl font-semibold text-primaryButtonBg mr-4">{currentTab?.name}</h2>
          <div className="bg-gray-100 rounded-lg p-1 inline-flex relative">
            {subTabs.map((subTab, index) => (
              <NavLink
                key={subTab.path}
                ref={el => subTabsRef.current[index] = el}
                to={`${currentTab?.path}/${subTab.path}`}
                className={`px-4 py-2 rounded-md transition-colors duration-200 relative z-10 whitespace-nowrap ${
                  activeSubTab === subTab.path ? 'text-primaryButtonBg' : 'text-componentText'
                }`}
              >
                {subTab.name}
              </NavLink>
            ))}
            <div
              className="absolute top-1 bottom-1 transition-all duration-200 bg-white rounded-md shadow-sm"
              style={highlightStyle}
            />
          </div>
        </div>
      )} */}
      <Outlet />
    </div>
  );
};

export default ToolManagement;