import React, { useState, useEffect } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { supabase } from '../../supabase'; 

interface Deadline {
  id: string;
  organization_id: string;
  title: string;
  due_date: string;
  organization_name: string;
}

const PendingDeadline: React.FC = () => {
  const [deadlines, setDeadlines] = useState<Deadline[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDeadlines();
  }, []);

  const fetchDeadlines = async () => {
    const positionId = localStorage.getItem('positionId');
    if (!positionId) {
      console.error('Position ID not found in local storage');
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase.rpc('get_pending_deadlines', {
        position_id: parseInt(positionId)
      });

      if (error) throw error;

      setDeadlines(data);
    } catch (error) {
      console.error('Error fetching deadlines:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (deadlineId: string) => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.error('User ID not found in local storage');
      return;
    }

    try {
      const { error } = await supabase.rpc('approve_deadline', {
        deadline_id: deadlineId,
        user_id: userId
      });

      if (error) throw error;

      await fetchDeadlines();
    } catch (error) {
      console.error('Error approving deadline:', error);
    }
  };

  const handleDelete = async (deadlineId: string) => {
    try {
      const { error } = await supabase.rpc('delete_deadline', {
        deadline_id: deadlineId
      });

      if (error) throw error;

      await fetchDeadlines();
    } catch (error) {
      console.error('Error deleting deadline:', error);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    return { month, day };
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Pending Deadlines For Organizations</h1>
      <div className="space-y-6">
        {deadlines.map((deadline) => {
          const { month, day } = formatDate(deadline.due_date);
          return (
            <div key={deadline.id} className="bg-white shadow-md rounded-lg p-6 flex justify-between items-center">
              <div className="flex-grow">
                <h2 className="text-xl font-bold text-gray-800">{deadline.organization_name}</h2>
                <p className="text-gray-600 mt-2">{deadline.title}</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="text-center mr-4">
                  <div className="text-sm font-semibold text-gray-500">{month}</div>
                  <div className="text-2xl font-bold text-gray-700">{day}</div>
                </div>
                <button 
                  onClick={() => handleApprove(deadline.id)}
                  className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600 transition duration-300"
                >
                  <FaCheck />
                </button>
                <button 
                  onClick={() => handleDelete(deadline.id)}
                  className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600 transition duration-300"
                >
                  <FaTimes />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PendingDeadline;