import { supabase } from '../supabase';

export const fetchUserData = async (userId: string) => {
  try {
    const { data, error } = await supabase
      .from('users')
      .select(`
        *,
        colleges (name)
      `)
      .eq('user_id', userId);

    if (error) throw error;
    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching user data:', error.message);
    }
    throw error;
  }
};
