import './App.css';
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import Login from './Pages/Misc/Login';
import Signup from './Pages/Misc/Signup';
import ForgotPassword from './Pages/Misc/ForgotPassword';
import { AuthProvider } from './Contexts/AuthContext';
import Settings from './Pages/Misc/Settings';
import ToolManagement from './Pages/Dashboard/Components/ToolManagement';
import Dashboard from './Pages/Dashboard/Dashboard';
import SettingsComponent from './Pages/Misc/Components/SettingsComponent';

import PrivacyPolicy from './Pages/Legal/Privacy';
import TermsAndConditions from './Pages/Legal/Terms';

import PrivateRoute from './Contexts/PrivateRoute';
import Layout from './Pages/Layout';

import PendingDeadline from './Pages/Tools/PendingDeadline';
import FinishedDeadline from './Pages/Tools/FinishedDeadline';
import CreateDeadline from './Pages/Tools/CreateDeadline';

const FallbackRoute = () => {
  let navigate = useNavigate();
  
  React.useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null;
}

export function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route element={<PrivateRoute />}>
            <Route element={<Layout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/settings" element={<Settings />}>
                <Route index element={<SettingsComponent />} />
              </Route>
              <Route path="/tools" element={<ToolManagement />}>
                <Route index element={<PendingDeadline />} />
                <Route path="finished" element={<FinishedDeadline />} />
                <Route path="create" element={<CreateDeadline />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<FallbackRoute />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}